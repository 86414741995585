<template>
  <page-title
    class="q-mb-lg"
    :title="$t('account.change_store_name')"
  ></page-title>
  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-up"
  >
    <Form
      class="q-gutter-md"
      :initial-values="
        Object.assign(
          {},
          initialValues
        ) /*Assign object to do not reactive dom object*/
      "
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <q-card-section class="justify-between form-inputs-content">
        <Field
          v-for="(structure, key) in structureForm"
          :key="key"
          :name="structure.name"
          v-slot="{ errorMessage, value, field }"
        >
          <q-input
            style="min-width: 100%"
            class="q-mb-sm"
            filled
            :dense="$q.platform.is.mobile"
            bottom-slots
            clearable
            :stack-label="structure['stack-label']"
            v-bind="field"
            :type="structure.type"
            :debounce="structure.debounce || null"
            :counter="!!structure.maxlength"
            :maxlength="structure.maxlength"
            :model-value="value"
            :label="structure.label"
            :error-message="errorMessage"
            :error="!!errorMessage"
          >
            <template v-slot:before><q-icon :name="structure.icon"/></template>
            <template v-if="structure.name === 'store_name'" v-slot:hint>
              <div v-for="(link, i) in my_url" :key="i">
                <strong v-html="$t('account.you_store')" />
                <a
                  class="text-grey-10"
                  style="font-weight: 500"
                  :href="my_url[i]"
                  >{{ my_url[i] }}</a
                >
              </div>
            </template>
          </q-input>
        </Field>
      </q-card-section>
      <q-card-section class="row q-mt-none q-pt-none">
        <div
          class="q-pa-md bg-red-2 default-rounded full-width "
          :class="{
            'q-mt-xl': $q.screen.width < 760,
          }"
        >
          <div class="text-justify">
            <q-icon
              name="warning"
              style="font-size: 15pt;"
              class="text-red q-mr-auto q-ml-auto "
            />
            <span
              :class="{
                'alert-font-size-mobile': $q.screen.width < 760,
                'alert-font-size-desktop': $q.screen.width >= 760,
              }"
            >
              Esteja ciente de que
              <b>ao alterar o seu identificador</b>, o
              <b>link anterior da sua loja não registrará mais vendas</b>. É
              crucial <b>atualizar os canais de divulgação</b>, se necessário,
              para garantir que
              <b>todas as vendas sejam contabilizadas corretamente</b>.
            </span>
          </div>
        </div>
      </q-card-section>
      <q-card-section class="row q-mt-none q-pt-none">
        <q-btn
          color="grey"
          outline
          class="full-width bg-grey-10 q-mt-sm q-pa-sm q-pr-lg q-pl-lg"
          type="submit"
          :label="$t('login.recover.submit')"
          :loading="in_request"
          :disable="in_request"
        />
      </q-card-section>
    </Form>
  </q-intersection>
</template>

<script>
import {
  getStructure,
  build,
  getMappedFields,
  formChanged,
} from "@/shared/form-presets";
import { notifySuccess, notifyError } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { Field, Form } from "vee-validate";
import { ref } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("account");
const partnerNamespaced = createNamespacedHelpers("partner");

export default {
  name: "ChangeStoreName",

  components: {
    PageTitle,
    Field,
    Form,
  },

  setup() {
    const { updateUserPartnerAction } = useActions(["updateUserPartnerAction"]);

    const { partner } = useGetters({
        partner: "getPartnerAccount",
      }),
      { my_url } = partnerNamespaced.useGetters({
        my_url: "getStoreLinks",
      });

    const in_request = ref(false),
      fieldsDefinitions = ["passwordValidation", "store_name"],
      structureForm = getStructure(fieldsDefinitions),
      schema = build(getMappedFields(fieldsDefinitions));

    let initialValues = {
      store_name: partner.value.slug,
    };

    const onSubmit = (values) => {
      if (formChanged(initialValues, values)) {
        initialValues = values;
        in_request.value = true;

        updateUserPartnerAction({ slug: values.store_name })
          .then(() => notifySuccess("actions.store_name_changed_success"))
          .catch(() => notifyError("actions.store_name_on_changed_error"))
          .finally(() => (in_request.value = false));
      } else {
        notifySuccess("actions.store_name_changed_success");
      }
    };

    return {
      in_request,
      schema,
      my_url,
      initialValues,
      structureForm,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.alert-font-size-mobile {
  font-size: 12px;
}
.alert-font-size-desktop {
  font-size: 14px;
}
</style>
